import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Helvetica',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#33691e',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    gray: {
      main: '#eeeeee',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.fruity.Mui-disabled": {
            color: "rgba(0,0,0,1)"
          }
        }
      }
    }
  },
});

export default theme;
