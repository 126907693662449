import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Grow from '@mui/material/Grow';
import Expire from './Expire'
import Fade from '@mui/material/Fade';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TwitterIcon from '@mui/icons-material/Twitter';
import Countdown from "react-countdown";

// 🥝🍒🍑🍋🍇🥶

/*
background color
ios app
seo - description, favicon, google search console.
aggregated metrics - average time to complete, win/loss rate, average win try or win try distribution.
user metrics - played, win percent, current streak, max streak, guess distribution 
*/

var moment = require('moment-timezone');
var nz = moment.tz(new Date(), "Pacific/Auckland");
//var nz = new Date().toLocaleDateString('en-CA', {timeZone: "Pacific/Auckland"})
//var seed = nz.replace(/-/g, "")

//moment.tz(new Date(), "Pacific/Auckland").add(1,'days').startOf('day').toDate()
//var tomorrow = new Date();
//tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
//var tomstr = tomorrow.toLocaleString('en-CA', {timeZone: "Pacific/Auckland"})

 
var seed = nz.format('YYYYMMDD');

// Local PRNG: does not affect Math.random.
var seedrandom = require('seedrandom');
var rng = seedrandom(seed);
var kiwi = Math.floor(rng() * 100)

function InfoDialog(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
    <IconButton onClick={handleClickOpen} color="primary" aria-label="help">
      <HelpOutlineIcon />
    </IconButton>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>How to play</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have 6 tries to find the kiwi. The kiwi is a number between 00-99 that changes daily. Each guess will reveal a fruit that indicates how far away your guess was.
        </DialogContentText>
        <Typography variant="h6" sx={{mt: 1}}>🥶 &nbsp;Ice Cold</Typography>
        <Typography variant="h6">🍇 &nbsp;Getting warmer</Typography>
        <Typography variant="h6">🍋 &nbsp;Balmy</Typography>
        <Typography variant="h6">🍑 &nbsp;Scorchin'</Typography>
        <Typography variant="h6">🍒 &nbsp;Red Hot!</Typography>
        <Typography variant="body2" color="text.secondary" sx={{mt:2, mb:1}}>After the game: Tap the fruit to reveal the verse of the day.</Typography>
        <Copyright />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
}

function Fruit(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let key = kiwi % 15

  const verses = ["Give your burdens to the Lord, and he will take care of you.","Ask, and it will be given to you; seek, and you will find; knock, and it will be opened to you.","Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God.","But seek first his kingdom and his righteousness, and all these things will be given to you as well.","Come to me, all who labor and are heavy laden, and I will give you rest.","Unless the Lord builds the house, those who build it labor in vain.","Some trust in chariots and some in horses, but we trust in the name of the Lord our God.","Let your steadfast love, O Lord, be upon us, even as we hope in you.","Trust in the Lord with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.","Not by might, nor by power, but by my Spirit, says the Lord of hosts.","Seek the Lord and his strength; seek his presence continually!","Let not the wise man boast in his wisdom, let not the mighty man boast in his might, let not the rich man boast in his riches, but let him who boasts boast in this, that he understands and knows me, that I am the Lord who practices steadfast love, justice, and righteousness in the earth.",'For the whole law is fulfilled in one word: "You shall love your neighbor as yourself."',"This is my commandment, that you love one another as I have loved you.","For God so loved the world, that he gave his only Son, that whoever believes in him should not perish but have eternal life."]

  return (
    <Box>
    <Grow in={props.in}>
    <Button className="fruity" disabled={props.tries > 0 && !props.won} onClick={handleClickOpen} sx={{ padding: 0 }}>
    <Typography variant="h2" align="center" sx={{ height: 60 }} >
      {props.children}
    </Typography>
    </Button>
    </Grow>
    <Dialog open={open} onClose={handleClose}>
    <DialogContent>
      <DialogContentText>
        {verses[key]}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
  </Box>
  );
}

class Display extends React.Component {
  constructor(props) {
    super(props);

    let a = moment(seed, "YYYYMMDD")
    let b = moment("20220215", "YYYYMMDD")
    let which = a.diff(b, 'days')+1

    this.state = {
      which: which,
      digits: [],
      fruit: "",
      enter: false,
      tries: 6,
      won: false,
      final: "",
      copied: false,
      kiwi: kiwi
    }

    let state = this.state
    if (localStorage.getItem('state')) {
      state = JSON.parse(localStorage.getItem('state'))
    }

    if (this.state.which == state.which) {
      this.state = state
      this.state.copied = false
    }

    updateDigits = updateDigits.bind(this)
    checkAnswer = checkAnswer.bind(this)
  }

  componentDidUpdate() {
    localStorage.setItem('state', JSON.stringify(this.state))
  }

  render() {

    let digit1, digit2, digits = "";
    if (this.state.digits[0] !== undefined) {
      digit1 = this.state.digits[0];
    }

    if (this.state.digits[1] !== undefined) {
      digit2 = this.state.digits[1];
      digits = parseInt(digit1 + digit2);
    }

    let info = this.state.tries
    let button = <Button sx={{ mt: 1, mr: 1 }} variant="contained" endIcon={<ShareIcon />} onClick={() => {
      if (navigator.share) {
        navigator.share({
          text: this.state.final
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
      else {
        navigator.clipboard.writeText(this.state.final)
        this.setState({copied: true})
      }
    }}>share</Button>

    const Completionist = () => <span>... oh wait, here's a new one now! (refresh the page)</span>;

    let countdown = ""
    let info2 = ""
    if (this.state.won) {
      info = "You found the kiwi!"
      info2 = "Find another in "
      countdown = <Countdown daysInHours={true} date={moment.tz(new Date(), "Pacific/Auckland").add(1,'days').startOf('day').toDate()}><Completionist /></Countdown>
    }
    else if (this.state.tries == 0) {
      info = "You didn't find the kiwi!"
      info2 = "Try again in "
      countdown = <Countdown daysInHours={true} date={moment.tz(new Date(), "Pacific/Auckland").add(1,'days').startOf('day').toDate()}><Completionist /></Countdown>
    }
    else {
      button = ""
    }

    let copied = ""
    if (this.state.copied) {
      copied = <Fade in={this.state.copied}><Typography variant="secondary" align="left" sx={{ position: "relative", top: 6 }}>Copied!</Typography></Fade>
    }

    return (
      <Box>
      
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={10} sx={{ height: 70 }}><Typography variant="secondary" align="left" component="p">{info}</Typography><Typography variant="secondary" align="left">{info2}</Typography>{countdown}<Box>{button}<Expire delay={2500}>{copied}</Expire></Box></Grid>
        <Grid item xs={2} sx={{ height: 70 }}><InfoDialog /></Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}><Fruit in={this.state.enter} digits={digits} tries={this.state.tries} won={this.state.won}>{this.state.fruit}</Fruit></Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography component="span" variant="h2" align="center" sx={{ mr: 1.5, borderBottom: 2, height: 70, width: 50 }}>{digit1}</Typography>
          <Typography component="span" variant="h2" align="center" sx={{ borderBottom: 2, height: 70, width: 50 }}>{digit2}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>1</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>2</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>3</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>4</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>5</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>6</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>7</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>8</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>9</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray" startIcon={<BackspaceIcon />} 
          onClick={(e) => updateDigits("BKSP")}>&nbsp;</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => updateDigits(e.target.innerText.trim())}>0</Button>
        </Grid>
        <Grid item xs={4}>
          <Button disabled={this.state.tries == 0 || this.state.won} variant="contained" sx={{ width: 1 }} size="large" color="gray"
          onClick={(e) => checkAnswer()}>ENTER</Button>
        </Grid>
      </Grid>
      </Box>
    );
  }
}

function checkAnswer() {
  if (!this.state.enter && !this.state.won && this.state.digits.length == 2 && this.state.tries > 0) {

    this.setState({ enter: true })
    let answer = parseInt(this.state.digits[0] + this.state.digits[1])
    let diff = Math.abs(this.state.kiwi - answer)
    let fruit = ""
    let won = false
    if (diff > 21) {
      fruit = "🥶"
    }
    else if (diff > 8) {
      fruit = "🍇"
    }
    else if (diff > 3) {
      fruit = "🍋"
    }
    else if (diff > 1) {
      fruit = "🍑"
    }
    else if (diff == 1) {
      fruit = "🍒"
    }
    else if (diff == 0) {
      fruit = "🥝"
      won = true
    }

    let final = this.state.final + fruit
    if(this.state.tries == 1 && !won) {
      final = 'Kiwi ' + this.state.which + ' X/6\n' + final
    }
    else if (won) {
      let finalTries = 6 - this.state.tries + 1
      final = 'Kiwi ' + this.state.which + ' ' + finalTries.toString() + '/6\n' + final
    }

    this.setState({fruit: fruit, tries: this.state.tries - 1, won: won, final: final})
  }
}

function updateDigits(digit) {
  /*
    if this.state.digits.length < 2
      state.digits.append(digit)
    
  */
  if (!this.state.won && this.state.tries > 0) {
    if (digit == 'BKSP') {
      if (this.state.digits.length > 0) {
        let updated = this.state.digits
        updated.pop();
        this.setState({ digits: [...updated], enter: false})
      }
    }
    else if (this.state.digits.length < 2) {
      let updated = this.state.digits
      updated.push(digit)
      this.setState({ digits: [...updated]})
    }
    else if (this.state.enter) {
      this.setState({ digits: [digit], enter: false })
    }
  }
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="left" sx={{mt: 0, display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'}}>
      © findthekiwi 2022
    <Link sx={{ ml: .5  }} href="https://twitter.com/findthekiwi">
      <TwitterIcon />
    </Link>
    </Typography>
  );
}

export default function App() {

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Display />
      </Box>
      <Typography variant="body2" align="center" sx={{mt: 1}}>
        <span style={{letterSpacing: '.1em'}}>❤️</span> Kiwi? 
        <Link sx={{ ml: .5 }} href="https://ko-fi.com/findthekiwi">
          Buy me a <span style={{letterSpacing: '.1em'}}>☕️</span>!
        </Link>
      </Typography>
    </Container>
  );
}